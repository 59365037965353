import { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TOP_TOPIC_FILTER_VALUES } from "app/constants";
import { productShape } from "app/utils/propTypes";
import {
	computeMonthFilterValues,
	computeDestinationFilterValues,
	computeZoneFilterValues,
	computePeriodFilterValues,
	computeBadgeFilterValues,
	computeTopicFilterValues,
	generateFacetCollection,
} from "app/utils/productFilterUtils";
import ProductsFilter from "app/pages/Listing/ProductFilters/ProductsFilter";
import useOnSticky from "app/pages/.shared/useOnSticky";

const computeFilterValues = (products = []) => {
	return {
		topics: computeTopicFilterValues(products),
		destinations: computeDestinationFilterValues(products),
		zones: computeZoneFilterValues(products),
		periods: computePeriodFilterValues(products),
		months: computeMonthFilterValues(products),
		badges: computeBadgeFilterValues(products),
	};
};

const ProductListBlockFilter = ({ products, filterProduct = () => {} }) => {
	const filtersRef = createRef();
	const [productsFilters, setProducsFilters] = useState({});
	const [facetQuery, setFacetQuery] = useState(
		generateFacetCollection(products, productsFilters)
	);

	const isElementOnScreen = useOnSticky({ ref: filtersRef });

	const updateFacetQuery = (products, productsFilters) => {
		setFacetQuery(generateFacetCollection(products, productsFilters));
	};

	useEffect(() => {
		updateFacetQuery(products, productsFilters);
	}, [productsFilters]);

	useEffect(() => {
		filterProduct(facetQuery.selectedItems().toJS());
	}, [facetQuery]);

	const handleFilterChange = filterBy => {
		setProducsFilters({
			...productsFilters,
			...filterBy,
		});
	};

	const handleUnsetProductsFilter = name => {
		setProducsFilters(prevProductsFilters => {
			const cloneProductFilter = { ...prevProductsFilters };
			delete cloneProductFilter[name];
			return cloneProductFilter;
		});
	};

	const filterValues = computeFilterValues(products);

	return (
		<ProductsFilter
			productsFilters={productsFilters}
			selectedFacets={facetQuery?.selectedFacets()?.toJS() || products}
			destinationFilterValues={filterValues?.destinations || []}
			zoneFilterValues={filterValues?.zones || []}
			topTopicFilterValues={TOP_TOPIC_FILTER_VALUES}
			topicFilterValues={filterValues?.topics || []}
			periodFilterValues={filterValues?.periods || []}
			monthsFilterValues={filterValues?.months || []}
			badgesFilterValues={filterValues?.badges || []}
			setProductsFilter={handleFilterChange}
			visibleFilters={facetQuery?.selectedFacetValues()?.toJS() || []}
			filtersRef={filtersRef}
			unsetProductsFilter={handleUnsetProductsFilter}
			isStuck={isElementOnScreen}
			className="product-list-block__filter"
		/>
	);
};

ProductListBlockFilter.propTypes = {
	products: PropTypes.arrayOf(productShape),
	filterProduct: PropTypes.func,
};

export default ProductListBlockFilter;
